exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cari-js": () => import("./../../../src/pages/cari.js" /* webpackChunkName: "component---src-pages-cari-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/DefaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-materi-page-js": () => import("./../../../src/templates/MateriPage.js" /* webpackChunkName: "component---src-templates-materi-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-tentang-kami-page-js": () => import("./../../../src/templates/TentangKamiPage.js" /* webpackChunkName: "component---src-templates-tentang-kami-page-js" */)
}

